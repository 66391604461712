/**
 * 企业招聘管理
 */
export default {
  // 招聘信息
  recruitmentInformation: {

  },
  // 简历投递
  resumeSubmit: {

  },
  // 招聘专场
  special: {
    // 编辑
    add: "social_recruiting_management_add",
    edit: "social_recruiting_management_edit",
    open: "social_recruiting_management_open",
    del: "social_recruiting_management_del",
    spPosition: {
      add: "social_recruiting_management_position_add",
      del: "social_recruiting_management_position_del",
    }
  }
}
