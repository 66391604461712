// 服务地址;
let option = {
  authority: '/api/dscloud-authority-center', //权限
  organization: '/api/dscloud-saas-platform',
  user: '/api/dscloud-account-center', //用户
  project: '/api/project',
  cs: '/hou',
  agreement: '/api/dscloud-app-forum/web',
  city: '/api/project',
  integral: '/api/dscloud-member-mall', // 会员积分服务
  // integral: "/integral", // 会员积分服务
  bus: '/api/dscloud-app-forum',
  rcube: '/api/dscloud-appservice-management',
  modular: '/api/dscloud-appservice-management', // 页面管理
  ad: '/api/ad', // 页面管理项目下拉,
  policy: '/api/ext-ies-integration', // 政策管理
  enterprise: '/api/enterprise',
  recruit: '/api/dscloud-ies-recruit', // 社招服务
  demand: '/api/dscloud-ies-demand', // 需求池
  calendarActivity: '/api/dscloud-ies-platform/activity',
  checkProject: '/api/enterprise', // 查项目
  questionnaire: '/api/dscloud-ies-questionnaire', // 问卷
  integralManagement: '/api/dscloud-member-points', // 积分
  test: '/test',
  enterpriseApi: '/api/dscloud-enterprise-center', // 需求池,
  appForumWeb: '/api/dscloud-app-forum/web',
  advertising: '/api/ad', // 广告
  enterpriseManagement: '/api/dscloud-ies-platform', // 企业展示管理
};

//请求地址
export default {
  // 查询当前用户可使用项目
  FIND_CURRENT_USER_AUTHORITY_PROJECT_LIST: option.authority + '/userRole/getProjects',
  // 查询当前用户可使用项目新(顺义项目)
  FIND_CURRENT_USER_AUTHORITY_PROJECT_LIST_NEW: option.project + '/saas/project/userRole/treeProjectList',
  //运营管理平台--用户管理
  USER_MESSAGE_LIST: option.user + '/oauthUserInfoController/getUserList', // 用户管理列表
  USER_MESSAGE_DETAIL: option.user + '/oauthUserInfoController/getUserInfo', // 用户详情
  USER_MESSAGE_EDIT: option.user + '/userProjectController/updateUserProject', // 编辑用户
  USER_MESSAGE_FREEZE: option.user + '/oauthUserInfoController/freezeUser', //冻结/解冻用户
  USER_MESSAGE_EXPORT: option.user + '/oauthUserInfoController/export', //导出
  USER_MESSAGE_PROJECT: option.project + '/project/list', // 查询项目列表
  agreementList: option.agreement + '/agreement/list', // 查询协议列表
  findById: option.agreement + '/agreement/findById/', // 查询协议详情
  agreementInsert: option.agreement + '/agreement/insert', // 协议添加
  agreementDeleted: option.agreement + '/agreement/deleted/', // 协议删除
  agreementUpdate: option.agreement + '/agreement/update', // 协议编辑
  SELECTCITYPAGE: option.city + '/city/selectCityPage', // 分页查询城市管理
  UPDATECITYBYID: option.city + '/city/updateCityById', // 修改城市图片 排序
  DELETEIMAGEBYID: option.city + '/city/deleteImageById', // 删除城市图片
  SELECTBYID: option.city + '/city/selectById', //  查询城市详情
  // 会员积分商品服务
  GET_INTEGRAL_GOODS_LIST: option.integral + '/web/v2/product/list', // 查询积分商品列表
  // 项目列表
  GET_PROJECT_LIST: option.integral + '/web/product/projectList',
  // 商品类型列表
  GET_PRODUCT_LIST: option.integral + '/web/product/getPublicData/',
  // 创建自营商品
  ADD_PRODUCT: option.integral + '/web/v2/product/add',
  UPDATE_PRODUCT: option.integral + '/web/product/update',
  DETAIL_PRODUCT: option.integral + '/web/v2/product/detail/', // 详情
  UPDATE_STATUS: option.integral + '/web/v2/product/update/status/', // 上下架
  UPDATE_FROZEN: option.integral + '/web/product/update/frozen/status/', // 冻结解冻
  EXPORT_PRODUCT: option.integral + '/web/product/list/export', // 导出商品
  GET_ACTIVE_ID: option.integral + '/web/product/getWoolStockByActiveId/', // 导出商品
  GET_ALL_FLOOR: option.integral + '/web/floor/getAll', // 查询楼层

  // 楼层商品管理
  GET_FLOOR: option.integral + '/web/floor/get', // 查询楼层
  GET_FLOORPRODUCT_LIST: option.integral + '/web/floorProduct/list', // 楼层商品管理列表
  EDIT_FLOORPROFUCT: option.integral + '/web/floorProduct/update', // 修改楼层商品
  FLOORPRODUCT_GETBYID: option.integral + '/web/floorProduct/getById', // 楼层商品详情接口
  DELETE_FLOORPRODUCT: option.integral + '/web/floorProduct', // 删除楼层商品
  DISABLE_FLOORPRODUCT: option.integral + '/web/floorProduct/disable', // 启用禁用楼层商品

  // 积分规则
  // 列表
  GET_INTEGRAL_RULE_LIST: option.integral + '/web/rule/list',
  // 添加
  INSERT_INTEGRAL_RULE: option.integral + '/web/rule/insert-rule',
  // 查询一条
  FIND_ONE_RULE_BY_ID: option.integral + '/web/rule/findOne',
  // 启用禁用
  TO_ENABLED_AND_DISABLED_RYLE: option.integral + '/web/rule',
  // 删除
  DELETE_RULE_BY_ID: option.integral + '/web/rule',
  // 修改
  UPDATE_INTEGRAL_RULE_BY_ID: option.integral + '/web/rule/update-rule',

  // 订单管理
  ORDER_LIST: option.integral + '/web/v2/order/list', // 订单列表
  ORDER_CLOSE: option.integral + '/web/order/close', // 关闭订单
  ORDER_DETAL: option.integral + '/web/order/detail', // 查看订单详情
  ORDER_EXPORT: option.integral + '/web/order/export', // 订单导出

  // 签到管理
  SIGN_DETAILS: option.integral + '/app/sign/details', // 签到详情
  SIGN_RULE: option.integral + '/app/sign/rule', // 修改签到详情

  //楼层管理
  FLOOR_LIST: option.integral + '/web/floor/list', // 楼层列表
  FLOOR_ADD: option.integral + '/web/floor/add', // 新增楼层
  FLOOR_INFO: option.integral + '/web/floor/detail', // 楼层详情
  FLOOR_UPDATE: option.integral + '/web/floor/update', // 楼层详情
  FLOOR_DISABLE: option.integral + '/web/floor/disable', // 楼层详情
  FLOOR_DELETE: option.integral + '/web/floor', // 楼层详情

  INSERTCOMMUTERBUS: option.bus + '/commuter/bus/insertCommuterBus', //  添加通勤巴士
  GETBUSBYID: option.bus + '/commuter/bus/getBusById/', //  通勤巴士详情
  UPDATECOMMUTERBUS: option.bus + '/commuter/bus/updateCommuterBus', //  通勤巴士修改
  DELETEDCOMMUTERBUS: option.bus + '/commuter/bus/deletedCommuterBus/', //  通勤巴士删除
  GETBUSLIST: option.bus + '/commuter/bus/getBusList/', //  通勤巴士列表
  SETSEQUENCE: option.bus + '/commuter/bus/setSequence', //  通勤巴士设置排序
  GETPROJECTBYUSERID: option.bus + '/commuter/bus/getProjectByUserId', //  通勤巴士获取用户项目

  // 弹窗管理
  POPUPLIST: option.agreement + '/popup/list', //  弹窗列表
  POPUPINSERT: option.agreement + '/popup/insert', //  添加弹窗
  GETPOPUPBYPOPUPID: option.agreement + '/popup/getPopupByPopupId', //  查看弹窗信息
  POPUPUPDATE: option.agreement + '/popup/update', //  编辑非系统弹窗
  POPUPUPDATESYS: option.agreement + '/popup/updateSys', //  编辑系统弹窗
  ENABLEPOPUPBYPOPUPID: option.agreement + '/popup/enablePopupByPopupId', //  启用弹框
  DISABLEPOPUPBYPOPUPID: option.agreement + '/popup/disablePopupByPopupId', //  禁用弹框
  CHECKPRIORITY: option.agreement + '/popup/checkPriority', //  检查优先级是否重复
  DELETEPOPUPBYPOPUPID: option.agreement + '/popup/deletePopupByPopupId', //  删除弹框
  POPUPEXCELUPLOAD: option.agreement + '/popup/excelUpload', //   指定人群上传Excel文件
  GETSELECTLIST: option.agreement + '/popupType/list', //  获取非系统的弹框类型列表
  LISTALL: option.agreement + '/popupType/listAll', //  获取所有的弹框类型
  UPLOADAGAIN: option.agreement + '/popup/uploadAgain', //   重新上传用户数据
  DELETEBATCH: option.agreement + '/popup/deleteBatch', //   批量删除上传的用户数据
  UPLOADEXCELLIST: option.agreement + '/popup/uploadExcelList', //   分页查询用户的数据
  GET_PROJECT_LIST_BY_ACTIVITY_ID: option.agreement + '/year/activity/getActivityProject', // 根据活动id查询对应的项目列表
  SELECT_ALL_ACTIVITY: option.agreement + '/year/activity/listAvailable',

  // 魔方管理
  SELECTRUBIKPAGE: option.rcube + '/rubik/selectRubikPage', //   魔方列表查询
  ADDRUBIK: option.rcube + '/rubik/addRubik', //   魔方管理新增
  SELECTRUBIKDETAILSBYID: option.rcube + '/rubik/selectRubikDetailsById', //   魔方基本信息查询
  UPDATERUBIKBYID: option.rcube + '/rubik/updateRubikById', //   魔方信息编辑
  DELETERUBIKBYID: option.rcube + '/rubik/deleteRubikById', //   魔方删除
  SELECTRUBIKTYPE: option.rcube + '/rubik/selectRubikType', //    魔方类型查询
  SELECTSERVICELISTBYNAME: option.rcube + '/servicemanagement/web/serviceinfo/selectServiceListByName', //服务列表下拉框

  // 模块绑定管理
  MODULAR_PROJECT_LIST: option.modular + '/modularProject/selecctModularProjectLiist', // 模块管理列表
  ADD_MODULE_BINDING: option.modular + '/modularProject/insertModularProject', // 新增模块
  EDIT_MODULE_BINDING: option.modular + '/modularProject/updateModularProject', // 编辑模块
  PROJECT_LIST: option.ad + '/ad/projectList', // 项目下拉框

  // 首页模块管理
  MODULAR_INFO_LIST: option.modular + '/modularInfo/modularInfoList', // 首页模块管理列表
  ADD_MODULE: option.modular + '/modularInfo/insertModularInfo', // 新增模块
  DELETE_MODULE: option.modular + '/modularInfo/deleteModularInfo', // 删除模块
  MODULAR_INFO_BYID: option.modular + '/modularInfo/selectModularInfoById', // 查看详情
  EDIT_MODULE: option.modular + '/modularInfo/updateModularInfo', // 编辑模块
  SELECT_MODULAR_INFO_LIST: option.modular + '/modularInfo/selectmodularInfoList', // 模块名称下拉框
  SELECT_MODULAR_PROJECT_BYID: option.modular + '/modularProject/selectModularProjectById', // 模块管理详情
  DELETE_MODULAR_PROJECT: option.modular + '/modularProject/deletedModularProject', // 删除
  ENABLE_DISABLE: option.modular + '/modularProject/updateModularProjectState', // 启用&禁用
  RUBIK_BYTITLE_NUMBER: option.modular + '/rubik/selectRubikByTitleOrNumber', // 魔方编号

  // 服务管理
  SERVICE_TYPE_LIST: option.modular + '/servicemanagement/web/serviceinfo/servicetypelist', // 服务管理列表
  ADD_SERVICE_INFO: option.modular + '/servicemanagement/web/serviceinfo/add', // 新增服务管理列表
  GET_TYPE_LIST: option.modular + '/servicemanagement/web/serviceinfo/typeList', // 查询服务类型
  EDIT_SERVICE_INFO: option.modular + '/servicemanagement/web/serviceinfo/update', // 编辑服务管理
  DETAIL_SERVICE_INFO: option.modular + '/servicemanagement/web/serviceinfo/findbyid', // 查看详情
  SERVICE_NUMBER_LIST: option.modular + '/servicemanagement/servicenumber/list', // 页面入口下拉列表
  NEW_SERVICE_LIST: option.modular + '/v2/service', // 新的服务列表
  NEW_ADD_SERVICE: option.modular + '/v2/service', // 新的添加服务
  NEW_DETAIL_SERVICE_INFO: option.modular + '/v2/service/', // 新的查询详情
  NEW_EDIT_SERVICE: option.modular + '/v2/service', // 服务修改

  // 服务绑定管理
  SERVICE_BINDING_INFO: option.modular + '/servicemanagement/web/bindinginfo/list', // 服务绑定列表
  BINDING_SERVICER_LIST: option.modular + '/servicemanagement/web/serviceinfo/servicelist', // 获取服务名称
  BINDING_PROJECT_LIST: option.modular + '/servicemanagement/web/binding/projectList', // 获取项目名称
  ADD_SERVICE_BINDING: option.modular + '/servicemanagement/web/bindinginfo/add', // 新增
  BINDING_INFO_BYID: option.modular + '/servicemanagement/web/bindinginfo/findbyid', // 查看服务绑定详情
  EDIT_SERVICE_BINDING: option.modular + '/servicemanagement/web/bindinginfo/update', // 编辑
  SERVICE_LIST: option.modular + '/servicemanagement/web/bindinginfo/servicelist', // 根据id查询对应的服务
  GET_EQUITY_MANAGEMENT_LIST: option.modular + '/web/benefit/v2/page',
  INSERT_EQUITY_MANAGEMENT: option.modular + '/web/benefit/v2',
  DELETE_EQUITY_BY_ID: option.modular + '/web/benefit/v2',
  GET_EQUITY_MANAGEMENT_BY_ID: option.modular + '/web/benefit/v2/details',
  UPDATE_EQUITY_MANAGEMENT_BY_ID: option.modular + '/web/benefit/v2',
  // 权益绑定列表
  GET_EQUITY_BINDING_LIST: option.modular + '/web/benefit-binding/v2/page',
  // 查询当前项目下的权益
  GET_EQUITY_BY_PROJECT_ID: option.modular + '/web/benefit/v2/list',
  // 查询当前项目下的所属模块
  // GET_MODULE_BY_PROJECT_ID: option.modular + '/app/module/query/list',
  GET_MODULE_BY_PROJECT_ID: option.modular + '/app/module/v2/query/list',
  // TODO
  // 新增权益绑定
  INSERT_EQUITY_BINDING: option.modular + '/web/benefit-binding/v2',
  // 启用禁用权益绑定
  TO_START_USING_AND_FORBIDDEN: option.modular + '/web/benefit-binding/v2/update-status',
  // 查询一条权益绑定信息
  FIND_EQUITY_BINDING_BY_ID: option.modular + '/web/benefit-binding/v2/details',
  // 删除权益绑定
  DELETE_EQUITY_BINDING_BY_ID: option.modular + '/web/benefit-binding/v2',
  // 权益绑定修改
  UPDATE_EQUITY__BINDING: option.modular + '/web/benefit-binding/v2',

  // 资讯管理
  CONSULTING_LIST: option.ad + '/web/consult/list', // 资讯管理列表
  CONSULTING_DELETE: option.ad + '/web/consult', // 资讯删除
  CONSULTING_EDIT: option.ad + '/web/consult/update', // 资讯编辑
  CONSULTING_DETAIL: option.ad + '/web/consult/detail', // 资讯详情
  CONSULTING_ADD: option.ad + '/web/consult/add', // 资讯添加
  CONSULTING_PUBLISH: option.ad + '/web/consult/update/status', // 资讯发布/撤回
  // 服务绑定管理
  UPDATE_OFTEN: option.modular + '/servicemanagement/web/bindinginfo/updateOften', // 修改服务绑定是否常用
  // 资讯管理获取项目
  GET_PROJECT: option.bus + '/project/list',
  // 新的服务绑定管理列表
  NEW_SERVICE_BINDING_LIST: option.modular + '/v2/web/service-binding/page',
  // 新的服务绑定详情
  GET_BINDING_INFO_BY_ID: option.modular + '/v2/web/service-binding/details',
  // 新的服务绑定添加
  NEW_ADD_SERVICE_BINDING: option.modular + '/v2/web/service-binding',
  // 新的服务名称list
  NEW_BINDING_SERVICER_LIST: option.modular + '/v2/service/full',
  // 查询模块list
  // FIND_MODULE_LIST: option.modular + '/app/module/query/toServer',
  FIND_MODULE_LIST: option.modular + '/app/module/v2/query/toServer',
  // 新的服务绑定编辑
  NEW_UPDATE_SERVICE_BINDING: option.modular + '/v2/web/service-binding',

  /**通行白名单管理 */
  // 获取工作人员白名单列表
  STAFF_WHITE_LIST: option.modular + '/web/work-user/user-list',
  // 禁用工作人员权限
  DISABLE_USER_AUTHORITY: option.modular + '/web/work-user/update-state',
  // 删除工作人员权限
  DELETE_USER_AUTHORITY: option.modular + '/web/work-user/delete-user',
  // 获取企业员工白名单列表
  GET_USER_WHITE_LIST: option.modular + '/web/epidemic/epidemic-user',
  // 禁用企业员工权限
  DISABLE_ENTERPRISE_USER_AUTHORITY: option.modular + '/web/epidemic/update-state',
  // 删除工作人员权限
  DELETE_ENTERPRISE_USER_AUTHORITY: option.modular + '/web/epidemic/delete-user',
  /**
   * 政策管理 */
  // 获取政策通报管理列表
  POLICY_REPORT_LIST: option.policy + '/web/inform',
  // 政策通报管理发布/取消发布
  POLICY_REPORT_PUBLISH: option.policy + '/web/inform/publish',
  // 政策通报管理-数据统计列表
  POLICY_REPORT_DATA_STATISTIC: option.policy + '/web/inform/view/record',
  // 政策通报管理添加
  POLICY_REPORT_ADD: option.policy + '/web/inform',
  // 政策通报管理详情
  POLICY_REPORT_DETAIL: option.policy + '/web/inform',
  // 政策通报管理删除
  POLICY_REPORT_DELETE: option.policy + '/web/inform',
  // 政策通报管理编辑
  POLICY_REPORT_EDIT: option.policy + '/web/inform',
  // 政策类型管理列表
  POLICY_TYPE_LIST: option.policy + '/web/inform/type',
  // 政策类型添加
  POLICY_TYPE_ADD: option.policy + '/web/inform/type',
  // 政策类型启用/禁用
  POLICY_TYPE_ENABLE: option.policy + '/web/inform/type/disabled',
  // 政策类型删除
  POLICY_TYPE_DELETE: option.policy + '/web/inform/type',
  // 政策类型编辑
  POLICY_TYPE_EDIT: option.policy + '/web/inform/type',
  // 政策类型详情
  POLICY_TYPE_DETAIL: option.policy + '/web/inform/type',
  /**
   * 服务管理企业家俱乐部
   */
  // 服务咨询列表
  SERVER_CONSULTATION_LIST: option.policy + '/web/consultation',
  // 服务咨询详情
  SERVER_CONSULTATION_DETAIL: option.policy + '/web/consultation',
  // 服务咨询-结束咨询/受理咨询
  SERVER_CONSULTATION_STATUS: option.policy + '/web/consultation',
  // 服务咨询-跟踪记录
  SERVER_CONSULTATION_RECORD: option.policy + '/web/consultation/trackRecord',
  // 服务咨询-跟踪记录详情
  SERVER_CONSULTATION_RECORD_DETAIL: option.policy + '/web/consultation/trackRecord',
  // 服务咨询-跟踪记录编辑
  SERVER_CONSULTATION_RECORD_EDIT: option.policy + '/web/consultation/trackRecord',
  // 服务咨询-服务跟踪记录删除
  SERVER_CONSULTATION_RECORD_DELETE: option.policy + '/web/consultation/trackRecord',
  // 服务咨询-服务跟踪记录添加
  SERVER_CONSULTATION_RECORD_ADD: option.policy + '/web/consultation/trackRecord',
  // 服务类型列表
  SERVER_TYPE_LIST: option.policy + '/web/consultation/type',
  // 服务类型详情
  SERVER_TYPE_DETAIL: option.policy + '/web/consultation/type',
  // 服务类型编辑
  SERVER_TYPE_EDIT: option.policy + '/web/consultation/type',
  // 服务类型删除
  SERVER_TYPE_DELETE: option.policy + '/web/consultation/type',
  // 服务类型添加
  SERVER_TYPE_ADD: option.policy + '/web/consultation/type',
  // 服务类型启用/禁用
  SERVER_TYPE_ENABLE: option.policy + '/web/consultation/type/disabled',
  /**
   * 职位管理
   */
  // 职位管理列表
  POSITION_LIST: option.policy + '/web/position',
  // 职位管理新增
  POSITION_ADD: option.policy + '/web/position',
  // 职位管理详情
  POSITION_DETAIL: option.policy + '/web/position',
  // 职位管理编辑
  POSITION_EDIT: option.policy + '/web/position',
  // 职位管理启用/禁用
  POSITION_ENABLE: option.policy + '/web/position/disabled',
  // 职位管理删除
  POSITION_DELETE: option.policy + '/web/position',
  // 所属项目
  GET_ALL_PROJECT_LIST: option.policy + '/web/inform/type/list/project',
  //  政策类型管理-政策类型
  POLICY_TYPE: option.policy + '/web/inform/type/list/down/box',
  // 所有职位名称
  GET_ALL_POSITION: option.enterprise + '/web/position/allPosition',

  // 模块管理列表
  MODULAR_LIST: option.modular + '/app/module/v2/query/list',
  // TODO
  // v2模块管理列表
  // NEW_MODULAR_LIST: option.modular + '/app/module/query/list',
  NEW_MODULAR_LIST: option.modular + '/app/module/v2/query/list',
  // 模块管理新增
  MODULAR_ADD: option.modular + '/app/module/insert',
  // 模块管理新增v2
  NEW_MODULAR_ADD: option.modular + '/app/module/v2/insert',
  // 模块管理详情
  MODULAR_INFO: option.modular + '/app/module/query',
  // 模块管理修改 / 启用禁用
  MODULAR_UPDATE: option.modular + '/app/module/v2/update',
  // 模块的禁用启用
  NEW_MODULAR_RESTART: option.modular + '/app/module/v2/update',
  //模块修改--v2
  NEW_MODULAR_UPDATE: option.modular + '/app/module/v2/update',
  // 详情
  MODULAR_INFO_BY_ID: option.modular + '/app/module/v2/query',
  // 模块管理删除
  MODULAR_DELETE: option.modular + '/app/module/delete',
  // v2删除模块
  NEW_MODULAR_DELETE: option.modular + '/app/module/v2/delete',
  // 模块管理服务列表
  MODULAR_SERVICE: option.modular + '/web/native',

  /**
   * 权益
   */
  // 所有人群列表
  GET_ALL_CROWD: option.modular + '/web/benefit/crowd-identity',
  // 权益身份绑定列表
  GET_EQUITY_IDENTITY_LIST: option.modular + '/web/benefit/page',
  // 修改权益身份绑定
  UPDATE_EQUITY_IDENTITY_BINDING_BY_ID: option.modular + '/web/benefit',
  // 权益项目绑定查询
  QUERY_EQUITY_BY_PROJECT_ID: option.modular + '/web/benefit-project/details',
  // 根据模块名称查询可以关联的群益身份
  QUERY_EQUITY_IDENTIFY_BY_MODULE_NAME: option.modular + '/web/benefit/benefit-crowd-identity',
  // 去绑定项目
  EQUITY_PROJECT_BINDING: option.modular + '/web/benefit-project',
  /**
   * 社招服务
   */
  // 人才招聘列表
  GET_RECRUITMENT_LIST: option.recruit + '/web/recruitment',
  // 人才招聘添加
  RECRUITMENT_ADD: option.recruit + '/web/recruitment',
  // 人才招聘详情
  RECRUITMENT_DETAIL: option.recruit + '/web/recruitment',
  // 人才招聘编辑
  RECRUITMENT_EDIT: option.recruit + '/web/recruitment',
  // 招聘信息取消发布/发布
  RECRUITMENT_PUBLISH: option.recruit + '/web/recruitment/release',
  //招聘信息删除
  RECRUITMENT_DELETE: option.recruit + '/web/recruitment',
  // 招聘信息下拉框数据
  RECRUITMENT_SELECT_LIST: option.recruit + '/web/recruitment/typeList',
  // 所属企业
  FIND_ENTERPRISE_INFO_BY_NAME: option.enterprise + '/enterprise/findEnterpriseInfoByName',
  // 简历投递列表
  RESUME_LIST: option.recruit + '/web/resume/list',
  // 简历投递最早时间
  GET_DELIVERY_DATE: option.recruit + '/web/resume/getDeliveryDate',
  // 简历投递查询企业
  RESUME_FIND_ENTERPRISE: option.recruit + '/web/resume/enterprise/fuzzySearch',
  // 重新发送邮件
  RESUME_RESEND: option.recruit + '/web/resume/resend',
  // 下载简历
  DOWNLOAD_RESUME: option.recruit + '/web/resume/downloadResume',
  // 批量下载简历
  BATCH_DOWNLOAD_RESUME: option.recruit + '/web/resume/downloadResumeList',
  // 批量导出
  BATCH_EXPORT_RESUME: option.recruit + '/web/resume/downloadResumeExcel',
  // 招聘专场列表
  GET_SPECIAL_LIST: option.recruit + '/web/recruit/special/list',
  // 招聘专场添加
  SPECIAL_ADD: option.recruit + '/web/recruit/special/addSpecial',
  // 招聘专场详情
  SPECIAL_DETAIL: option.recruit + '/web/recruit/special/getSpecialInfo',
  // 招聘专场编辑
  SPECIAL_EDIT: option.recruit + '/web/recruit/special/updateSpecial',
  // 招聘专场删除
  SPECIAL_DELETE: option.recruit + '/web/recruit/special/deleteSpecial',
  // 招聘专场状态
  SPECIAL_OPEN: option.recruit + '/web/recruit/special/updateSpecialState',
  // 招聘专场模块列表
  GET_SPECIAL_MODULE_LIST: option.recruit + '/web/recruit/special/getModuleListBySpecialId',
  // 招聘专场职位列表
  GET_SPECIAL_POSITION_LIST: option.recruit + '/web/recruit/special/getSpecialPositionList',
  // 招聘专场职位添加
  SPECIAL_POSITION_ADD: option.recruit + '/web/recruit/special/bindingPosition',
  // 绑定专场查询职位列表
  GET_SPECIAL_MODULE_POSITION_LIST: option.recruit + '/web/recruit/special/getBindingPositionList',
  // 招聘专场职位删除
  SPECIAL_POSITION_DELETE: option.recruit + '/web/recruit/special/deletePosition',
  // 招聘专场获取项目
  SPECIAL_POSITION_GETPROJECT: option.recruit + '/web/recruit/special/project/download',
  // 招聘专场获取企业名称项目
  SPECIAL_POSITION_GETENTERPRISE: option.recruit + '/web/recruit/special/enterprise/download',
  // 导出所有权益身份绑定数据
  EXPORT_EQUITY_IDENTIFY_DATA: option.modular + '/web/benefit/upload-excel',
  /**
   * 活动管理
   */
  // 列表
  GET_ACTIVITY_LIST: option.bus + '/web/activityInformation/app/list',
  // 禁用启用活动操作
  START_USING_AND_ENABLE_ACTIVITY: option.bus + '/web/activityInformation/updateState',
  // 根据id删除活动
  REMOVE_ACTIVITY_BY_ID: option.bus + '/web/activityInformation/deleted',
  // 新增活动
  ADD_ACTIVITY: option.bus + '/web/activityInformation/insert',
  // 根据id获取活动详情
  GET_ACTIVITY_BY_ID: option.bus + '/web/activityInformation/findById',
  // 修改活动
  UPDATE_ACTIVITY: option.bus + '/web/activityInformation/updateById',

  /**
   * 服务管理职位管理
   */
  // 职位管理列表
  POSITION_CONSULT_LIST: option.policy + '/web/consult/position',
  // 职位管理新增
  POSITION_CONSULT_ADD: option.policy + '/web/consult/position',
  // 职位管理详情
  POSITION_CONSULT_DETAIL: option.policy + '/web/consult/position',
  // 职位管理编辑
  POSITION_CONSULT_EDIT: option.policy + '/web/consult/position',
  // 职位管理启用/禁用
  POSITION_CONSULT_ENABLE: option.policy + '/web/consult/position/disabled',
  // 职位管理删除
  POSITION_CONSULT_DELETE: option.policy + '/web/consult/position',
  // 所有职位名称
  GET_ALL_CONSULT_POSITION: option.enterprise + '/web/consult/position/positions',
  // 服务管理处理人
  GET_SERVER_USER_LIST: option.policy + '/web/consultation/work/user/list',
  // 服务咨询管理所属项目
  GET_SERVER_PROJECT: option.policy + '/web/consultation/project/list',

  // 配置积分
  POINTS_LIST: option.rcube + '/web/work-integral/page',
  // 根据积分规则查询规则详情
  POINTS_RULE: option.rcube + '/web/work-integral/rule',
  // 积分添加
  POINTS_ADD: option.rcube + '/web/work-integral',
  // 积分详情
  POINTS_INFO: option.rcube + '/web/work-integral/details',
  // 积分启用/禁用
  POINTS_UPDATE_STATE: option.rcube + '/web/work-integral/update-state',
  // 积分模版下载
  POINTS_MODEL: option.rcube + '/web/work-integral/upload-merchant-model',
  /**服务咨询管理-供应商管理 */
  // 供应商列表
  SUPPLIER_LIST: option.policy + '/web/supplier',
  // 添加供应商
  ADD_SUPPLIER: option.policy + '/web/supplier',
  // 编辑供应商
  EDIT_SUPPLIER: option.policy + '/web/supplier',
  // 供应商详情
  SUPPLIER_DETAIL: option.policy + '/web/supplier/detail',
  // 删除供应商
  DELETE_SUPPLIER: option.policy + '/web/supplier',
  // 启用/禁用供应商
  ENABLE_SUPPLIER: option.policy + '/web/supplier/disabled',
  // 根据项目集合查询供应商集合
  SELECT_SUPPLIER_LIST: option.policy + '/web/supplier/list/down/box',
  // 获取供应商集合
  GET_SUPPLIER: option.policy + '/web/supplier/list',

  /**
   * 首页皮肤管理
   */
  // 皮肤新增
  SKIN_ADD: option.modular + '/web/index/skin',
  // 皮肤列表
  SKIN_LIST: option.modular + '/web/index/skin/list',
  // 皮肤删除
  SKIN_DELETE: option.modular + '/web/index/skin/deleteById',
  // 皮肤禁用/启用
  SKIN_ENABLED: option.modular + '/web/index/skin/updateStatus',
  // 皮肤详情
  SKIN_INFO: option.modular + '/web/index/skin/findOne',
  // 皮肤修改
  SKIN_UPDATE: option.modular + '/web/index/skin',
  /**需求池服务 */
  // 查询部门列表(需求池)
  GET_DEPARTMENT_LIST_FOR_DEMAND_POOL: option.demand + '/organization/list',
  // 添加部门(需求池)
  ADD_DEPARTMENT_FOR_DEMAND_POOL: option.demand + '/organization/addOrganization',
  // 删除部门(需求池)
  DELETE_DEPARTMENT_FOR_DEMAND_POOL: option.demand + '/organization/deleteOrganization',
  // 编辑部门(需求池)
  EDIT_DEPARTMENT_FOR_DEMAND_POOL: option.demand + '/organization/updateOrganization',
  // 查询人员列表(需求池)
  GET_STAFF_LIST_FOR_DEMAND_POOL: option.demand + '/user/list',
  // 查询角色类型列表(需求池)
  GET_ROLE_TYPE_LIST_FOR_DEMAND_POOL: option.demand + '/user/listDemandUserRoleType',
  // 添加人员(需求池)
  ADD_STAFF_FOR_DEMAND_POOL: option.demand + '/user/addUser',
  // 添加人员查询人员信息(需求池)
  ADD_STAFF_SEARCH_STAFF_INFO_FOR_DEMAND_POOL: option.demand + '/user/listDemandSaas',
  // 查询人员详情(需求池)
  GET_STAFF_DETAIL_FOR_DEMAND_POOL: option.demand + '/user/getUserInfo',
  // 编辑人员(需求池)
  EDIT_STAFF_FOR_DEMAND_POOL: option.demand + '/user/updateUser',
  // 删除人员(需求池)
  DELETE_STAFF_FOR_DEMAND_POOL: option.demand + '/user/deleteUser',
  // 获取需求分类列表(需求池)
  GET_DEMAND_CATEGORY_LIST_FOR_DEMAND_POOL: option.demand + '/classify/list',
  // 添加需求分类(需求池)
  ADD_DEMAND_CATEGORY_FOR_DEMAND_POOL: option.demand + '/classify/addDemandClassify',
  // 编辑需求分类(需求池)
  EDIT_DEMAND_CATEGORY_FOR_DEMAND_POOL: option.demand + '/classify/updateDemandClassify',
  // 删除需求分类(需求池)
  DELETE_DEMAND_CATEGORY_FOR_DEMAND_POOL: option.demand + '/classify/deleteFloor',
  //需求池-工单列表
  REQUIREMENT_POOL_LIST: option.demand + '/demandPool/web/list',
  //需求池-Web端需求池工单详情
  REQUIREMENT_POOL_DETAIL: option.demand + '/demandPool/web/webDemandInfo/',
  //需求池-需求类型
  REQUIREMENT_DEMANDTYPEALL: option.demand + '/demandPool/demandTypeAll',
  //需求池-工单状态
  REQUIREMENT_DEMANDSTATE: option.demand + '/demandPool/demandState',
  //需求池-项目
  REQUIREMENT_PROJECT: option.demand + '/demandPool/web/project/downBox',
  //需求池-企业信息
  REQUIREMENT_ENTERPRISE: option.demand + '/demandPool/web/enterprise/downBox',
  //需求池-需求发起者、服务负责人、服务协作人
  REQUIREMENT_HANDLEMSG: option.demand + '/demandPool/web/sourceOrPrincipalOrCooperation/downBox',
  //需求池-需求分类
  REQUIREMENT_CLASSIFY: option.demand + '/demandPool/web/classify/downBox',
  //需求池-需求服务类型
  REQUIREMENT_DEMANDSERVERTYPE: option.demand + '/demandPool/demandServerType',
  //需求池-需求类型
  REQUIREMENT_DEMANDTYPE: option.demand + '/demandPool/web/demandType/downBox',
  //需求池-批量导出
  REQUIREMENT_EXPORT: option.demand + '/demandPool/web/list/export',
  // 需求池-获取分类
  REQUIREMENT_GETCLASSFIY: option.demand + '/classify/web/all',
  // 需求服务类型下拉框
  DEMANDSERVERTYPE: option.demand + '/demandPool/demandServerType',
  // 内部需求类型下拉框
  INNERDEMANDTYPE: option.demand + '/demandPool/innerDemandType',
  // 项目负责人拒收工单
  REJECTIONORDER: option.demand + '/web/principal/rejection/',
  // 协作人提交服务
  COOPERATIONSUBMIT: option.demand + '/web/principal/cooperationSubmit',
  //   项目负责人提交服务
  PRINCIPALSUBMIT: option.demand + '/web/principal/principalSubmit',
  //   项目负责人接收工单
  RECEIVEORDER: option.demand + '/web/principal/receive/',
  //    工单归档
  PIGEONHOLE: option.demand + '/demandPool/web/pigeonhole',
  //  工单审核
  AUDITWORKORDER: option.demand + '/demandPool/web/auditWorkOrder',
  //  地点管理-列表
  WEBPLACELIST: option.demand + '/demandPool/web/place/list',
  //  地点管理-添加
  WEBPLACEADD: option.demand + '/demandPool/web/place/add',
  //  地点管理-编辑
  WEBPLACEUPDATE: option.demand + '/demandPool/web/place/update',
  //  地点管理-删除
  WEBPLACEDELETED: option.demand + '/demandPool/web/place/deleted/',
  //  商户管理--列表
  WEBTENANTLIST: option.demand + '/demandPool/web/tenant/list',
  //  商户管理--添加
  WEBTENANTADD: option.demand + '/demandPool/web/tenant/add',
  //  商户管理--编辑
  WEBTENANTUPDATE: option.demand + '/demandPool/web/tenant/update',
  //  商户管理--删除
  WEBTENANTDELETED: option.demand + '/demandPool/web/tenant/deleted/',
  //  通过手机号获取飞书openId
  GETFEISHUOPENID: option.demand + '/organization/feiShu/openId',
  //  结束工单 (内部-需求发起人)
  ONESELFFINISHWORKORDER: option.demand + '/demandPool/web/oneselfFinishWorkOrder',
  //  完成回访
  RETURNVISITTYPE: option.demand + '/demandPool/web/returnVisitType',
  //   取消回访
  CANCELRETURNVISIT: option.demand + '/demandPool/web/cancelReturnVisit/',
  //     模糊查询项目下企业信息
  FUZZYRETRIEVAL: option.enterpriseApi + '/enterprise/fuzzyRetrieval',
  //     模糊查询企业下的员工信息
  CUSTOMERFUZZYRETRIEVAL: option.enterpriseApi + '/staff/fuzzyRetrieval',
  // 400/统一客服发起工单
  SERVICEWORKORDER: option.demand + '/demandPool/web/serviceWorkOrder',
  //    模糊搜索分派/协作人列表
  APPGETDEMANDUSERLISTBYUSERNAME: option.demand + '/user/appGetDemandUserListByUserName',
  // 获取地点下拉框数据 by 项目id
  placeDownload: option.demand + '/demandPool/web/place/download',
  // 获取商户下拉框数据 by 项目id
  tenantDownload: option.demand + '/demandPool/web/tenant/download',
  // 获取需求池项目
  getProjectDownBox: option.demand + '/demandPool/web/enterprise/project/downBox',
  // 获取需求池项目（修改）
  getProjectDownBox2: option.demand + '/demandPool/web/innerWorkOrder/project/downBox?channelType=2',
  //  联系人职务下拉框
  LINKMANDUTY: option.demand + '/demandPool/linkmanDuty',
  // 内部发起工单
  INNERWORKORDER: option.demand + '/demandPool/web/innerWorkOrder',
  // 孵化器发起工单
  INCUBATORWORKORDER: option.demand + '/demandPool/web/incubatorWorkOrder',
  // 获取需求池列表
  innerWorkOrderList: option.demand + '/demandPool/web/innerWorkOrderList',
  // 结束工单 (统一客服工单)
  serviceFinishWorkOrder: option.demand + '/demandPool/web/serviceFinishWorkOrder',
  //  结束工单 (用户)
  USERFINISHWORKORDER: option.demand + '/demandPool/web/userFinishWorkOrder',
  //    分派/重新分派负责人
  ASSIGNWORKORDER: option.demand + '/demandPool/web/assignWorkOrder',
  //   项目负责人添加协作人
  ADDCOOPERATION: option.demand + '/web/principal/addCooperation',
  //   获取负责人or协作人 树形结构 (iyou工作台)
  GETDEMANDUSERTREE: option.demand + '/user/getDemandUserTree',
  // 读取草稿
  getWorkOrderDraft: option.demand + '/demandPool/getWorkOrderDraft',
  // 移除草稿
  removeWorkOrderDraft: option.demand + '/demandPool/removeWorkOrderDraft',
  // 存储草稿
  saveWorkOrderDraft: option.demand + '/demandPool/saveWorkOrderDraft',
  // 判断是否有草稿
  haveWorkOrderDraft: option.demand + '/demandPool/haveWorkOrderDraft',
  // 判断是否有草稿
  workOrderServerDescribe: option.demand + '/demandPool/web/workOrderServerDescribe',
  // 400获取项目
  get400ProjectList: option.demand + '/demandPool/web/enterprise/service/project/downBox',
  // 孵化器/内部获取项目
  getIncubatorProjectList: option.demand + '/demandPool/web/enterprise/projectList',

  /**
   * 年终活动
   */
  // 关键词组列表
  GET_KEY_WORD_LIST: option.bus + '/web/requite/keyword/v1/group/list',
  // 新增关键词组
  INSERT_KEY_WORD_GROUP: option.bus + '/web/requite/keyword/v1/group/add',
  // 关键词组详情
  GET_KEY_WORD_DETAIL_BY_ID: option.bus + '/web/requite/keyword/v1/group/detail',
  // 新增关键词
  INSERT_KEY_WORD: option.bus + '/web/requite/keyword/v1/add',
  // 删除关键词
  DELETE_KEY_WORD: option.bus + '/web/requite/keyword/v1/group/update',
  // 禁用启用
  RESTART_KEY_WORD: option.bus + '/web/requite/keyword/v1/group/update',
  // 编辑关键词
  UPDATE_KEY_WORD_BY_ID: option.bus + '/web/requite/keyword/v1/group/update',
  // 奖池list
  JACKPOT_LIST: option.bus + '/web/year-pond/list',
  // 新增奖池
  INSERT_JACKPOT: option.bus + '/web/year-pond',
  // 删除奖池
  DELETE_JACKPOT_BY_ID: option.bus + '/web/year-pond',
  // 查看奖池详情
  FIND_ONE_JACKPOT: option.bus + '/web/year-pond',
  // 编辑奖池
  UPDATE_JACKPOT: option.bus + '/web/year-pond',
  // 人群list
  SELECT_CROWDS: option.bus + '/web/crowd-identity/list',
  // 根据项目id查询所属人群
  SELECT_ALL_CROWDS_BY_PROJECT_ID: option.bus + '/app/procession/category',
  // 获取关联信息（优惠券）
  GET_CONTACT_INFO: option.bus + '/web/year-pond/getContactInfo',
  // 获取关联信息（积分）
  GET_CONTACT_POIN_INFO: option.bus + '/web/year-pond/getContactPointInfo',
  // 年终活动列表
  GET_YEAR_END_ACTIVITIES: option.bus + '/web/year/activity/list',
  // 查询所有关键词组
  SELECT_ALL_KEY_WORD_LIST: option.bus + '/web/requite/keyword/v1/getList',
  // 修改年终活动
  UPDATE_YEAR_END_ACTIVITY: option.bus + '/web/year/activity',
  // 新增年终活动
  INSERT_YEAR_END_ACTIVITY: option.bus + '/web/year/activity',
  // 查询活动详情
  SELECT_YEAR_END_ACTIVITY_BY_ID: option.bus + '/web/year/activity',
  // 启用禁用年终活动
  UPDATE_ACTIVITY_STATUS: option.bus + '/web/year/activity/state',
  // 获取活动数据
  GET_ACTIVITY_DATA: option.bus + '/web/year/activity/get-activity-data',
  // 战队活动数据
  GET_PK_ACTIVITY_DATA: option.bus + '/web/year/activity/data/list',
  // 获得开工红包奖池数据
  GET_ACTIVITY_INFO_BY_ID: option.bus + '/web/year-pond',
  // 导出活动数据
  EXPORT_ACTIVITY_DATA: option.bus + '/web/year/activity/exportActivityData',
  // 获取当前用户的获得积分的list
  GET_USER_INTEGRAL_BY_ID: option.bus + '/app/procession/record',
  // 根据项目id获取奖池列表
  SELECT_JACKPOT_LIST_BY_PROJECT_ID: option.bus + '/web/year-pond/canAssociationPond',
  // 查询战队数据
  SELECT_TEAMS_DATA: option.bus + '/web/year/activity/total/score',
  // 导出pk战队活动数据
  EXPORT_PK_ACTIVITY_DATA: option.bus + '/web/year/activity/export/data/list',
  // 开工活动活动数据
  GET_START_ACTIVITY_DATA: option.bus + '/web/year/activity/getTreeActivityData',
  // 发财树活动数据导出
  EXPORT_MONEY_TREE_ACTIVITY_DATA: option.bus + '/web/year/activity/exportTreeActivityData',

  /**
   * 数字人管理
   */
  ADD_DIGITAL_PERSON_CONFIG: option.modular + '/web/digital/personal/add',
  UPDATE_DIGITAL_PERSON_CONFIG: option.modular + '/web/digital/personal/update',
  GET_DIGITAL_PERSON_CONFIG: option.modular + '/web/digital/personal/list',
  SELECT_DIGITAL_PERSON_BY_ID: option.modular + '/web/digital/personal/detail',
  UPDATE_DIGITAL_PERSON: option.modular + '/web/digital/personal/updatePerson',
  UPDATE_DIGITAL_PERSON_OPEN_STATUS: option.modular + '/web/digital/personal/updateOpenAnimation',
  DELETE_DIGITAL_PERSON: option.modular + '/web/digital/personal/delete',

  /**
   * 限时领券
   */
  // 限时领券列表
  GET_LIMITED_TIME_COUPON_LIST: option.modular + '/web/timelimit/coupon/list',
  // 新增限时领券
  INSERT_LIMITED_TIME_COUPON: option.modular + '/web/timelimit/coupon/addCoupon',
  // 修改限时领券
  UPDATE_LIMITED_TIME_COUPON: option.modular + '/web/timelimit/coupon/updateCoupon',
  // 查询限时领券详情
  GET_LIMITED_TIME_COUPON_BY_ID: option.modular + '/web/timelimit/coupon/detail',
  // 查询端点活动券信息
  GET_COUPON_INFO_BY_ID: option.modular + '/web/timelimit/coupon/getMallActivityById',
  // 禁用或启用
  TO_DISABLE_ENABLE_LIMITED_TIME_COUPON: option.modular + '/web/timelimit/coupon/changeCoupon',
  // 删除
  DELETE_LIMITED_tIME_COUPON_BY_ID: option.modular + '/web/timelimit/coupon/deleteCoupon',

  /**月历
   * */
  // 月历列表
  GET_LIST_ACTIVITY: option.calendarActivity + '/pageList',
  // 月历活动查看详情
  GET_LIST_DETAIL: option.calendarActivity + '/viewActivityById',
  // 月历活动查看编辑
  GET_LIST_MOD: option.calendarActivity + '/modActivityById',
  // 月历活动创建
  INSET_ACTIVITY: option.calendarActivity + '/insertActivity',
  // 月历活动更新
  UPDATE_CALENDER_ACTIVITY: option.calendarActivity + '/updateActivity',
  // 发布/取消发布校验
  GET_LIST_PUBLISH: option.calendarActivity + '/checkPublishOrCancelActivity',
  // 发布/取消发布
  SET_LIST_PUBLISH: option.calendarActivity + '/publishActivityById',
  // 导出报名信息
  GET_LIST_REGISTRANT: option.calendarActivity + '/registrant/exportActivityRegistrantByActivityId',
  // 下载模板
  GET_TEMPLATE: option.calendarActivity + '/personnel/exportActivityPersonnelTemplate',
  // 下载此活动指定用户信息
  GET_USER_LIST: option.calendarActivity + '/personnel/exportActivityPersonnelByActivityId',
  // 获取活动类型
  GET_LISTA_CTIVITYLIST: option.calendarActivity + '/getActivityTypeList',
  //     查询项目
  FINDPROJECT: option.checkProject + '/enterprise/findProject',
  //     查询公司
  FIND_ENTERPRIES_TYPE: option.checkProject + '/enterprise/findEnterpriseTypeByProjectIdList',
  // 获取问卷列表
  GET_PLATPORM_QUESTIONNAIRE: option.questionnaire + '/questionnaire/getPlatformQuestionnaireListForActivity',
  // 首页企业列表获取接口 /api/enterprise/enterprise/types/list
  enterpriseNamePlanB: option.enterprise + 'enterprise/types/list',
  // 上传指定用户文件
  importUserFile: option.calendarActivity + '/personnel/importUserFile',
  // 获取活动类型列表
  getTypeList: option.calendarActivity + '/type/list',
  // 添加活动类型列表
  addTypeList: option.calendarActivity + '/type/add',
  // 修改活动类型列表
  updateTypeList: option.calendarActivity + '/type/update',
  // 修改活动类型列表
  delTypeList: option.calendarActivity + '/type/',
  // 修改活动类型列表
  getTypeInfo: option.calendarActivity + '/type/',
  // 添加活动类所属项目
  getProjectDownload: option.calendarActivity + '/project/download',

  /**
   * 协议管理
   */
  GET_AGREEMENT_SIGNING_RECORD: option.bus + '/web/agreement/listSign',
  // 签署记录详情
  GET_AGREEMENT_SIGNING_RECORD_DETAIL: option.bus + '/web/agreement/getSignById',
  /**
   * 服务手册
   */
  // 服务手册列表
  GET_SERVICE_MANUAL_LIST: option.modular + '/web/service-book/list',
  // 启用或禁用
  SERVICE_MANUAL_DISABLED_ENABLED: option.modular + '/disabled',
  // 删除服务手册
  SERVICE_MANUAL_DELETE_BY_ID: option.modular + '/deleteById',
  // 查看详情
  FIND_ONE_SERVICE_MANUAL_BY_ID: option.modular + '/web/service-book/findById',
  // 添加服务手册
  INSERT_SERVICE_MANUAL: option.modular + '/web/service-book/add',
  // 编辑服务手册
  UPDATE_SERVICE_MANUAL: option.modular + '/web/service-book/update',
  // 获取所有父级目录
  FIND_ALL_DIRECTORY: option.modular + '/web/service-book/listAllParent',
  // 服务手册配置
  SERVICE_MANUAL_CONFIG: option.modular + '/web/service-book/updateContent',
  /**
   * 酒店业务管理
   */
  GET_HOTEL_USERINFO_LIST: option.modular + '/web/hotel/getHotelRecList',

  /**
   * 积分管理
   **/
  // 积分账户管理列表
  GET_INTEGRAL_ACCOUNT_LIST: option.integralManagement + '/web/vip-account/v2/list',
  // 当前用户积分详情
  GET_CURRENT_USER_INTEGRAL_INFO: option.integralManagement + '/web/vip-account/v2/details',
  // 保存积分返回比设置
  SAVE_INTEGRAL_RETURN_RATIO: option.integralManagement + '/web/scene-rule/v1/insert',
  // 编辑积分返回比设置
  EDIT_INTEGRAL_RETURN_RATIO: option.integralManagement + '/web/scene-rule/v1/update',
  // 积分返还比例列表
  GET_INTEGRAL_RETURN_RATIO_LIST: option.integralManagement + '/web/scene-rule/v1/list',
  // 积分返还比启用禁用
  INTEGRAL_RETURN_RATIO_DISABLED_ENABLE: option.integralManagement + '/web/scene-rule/v1/updateStatus',
  // 获取积分返还比详情
  GET_INTEGRAL_RETURN_RATIO_BY_ID: option.integralManagement + '/web/scene-rule/v1/details',
  // 新增发优惠券活动
  ADD_GRANT_COUPON_ACTIVITY: option.rcube + '/web/send-coupon/insert',
  // 获取优惠券活动列表
  GET_COUPON_ACTIVITY_LIST: option.rcube + '/web/send-coupon/query/page',
  // 获取优惠券活动详情
  GET_COUPON_ACTIVITY_DETAIL: option.rcube + '/web/send-coupon/query',
  // 编辑优惠券活动
  EDIT_GRANT_COUPON_ACTIVITY: option.rcube + '/web/send-coupon/update',
  // 优惠券活动启禁用
  COUPON_ACTIVITY_DISABLED: option.rcube + '/web/send-coupon/update',
  // 下载优惠券活动商户模版
  DOWNLOAD_COUPON_ACTIVITY_MERCHANT_TEMPLATE: option.rcube + '/web/send-coupon/exportExcel',
  // 查询积分规则信息
  FIND_POINTS_RULE_INFO: option.integralManagement + '/app/rule/find-details',
  /**榜单管理 */
  // 查询推荐内容列表
  FIND_RECOMMEND_CONTENT_LIST: option.appForumWeb + '/recommendContent/list',
  // 添加推荐内容
  ADD_RECOMMEND_CONTENT: option.appForumWeb + '/recommendContent/insertContent',
  // 查询推荐内容详情
  FIND_RECOMMEND_CONTENT_DETAIL: option.appForumWeb + '/recommendContent/getContentById',
  // 编辑推荐内容
  EDIT_RECOMMEND_CONTENT: option.appForumWeb + '/recommendContent/updateContent',
  // 删除推荐内容
  REMOVE_RECOMMEND_CONTENT: option.appForumWeb + '/recommendContent/deleteContent',
  // 导出推荐内容
  EXPORT_RECOMMEND_CONTENT: option.appForumWeb + '/recommendContent/export',
  // 查询榜单数据列表
  FIND_RANKING_LIST: option.appForumWeb + '/listData/list',
  // 导出榜单数据
  EXPORT_RANKING_LIST: option.appForumWeb + '/listData/export',
  // 自定义分值调整
  ADJUSTMENT_CUSTOM_SCORE: option.appForumWeb + '/listData/integralGoverning',
  // 获取榜单周期列表
  GET_RANKING_PERIOD_LIST: option.appForumWeb + '/listData/getrankList',

  /**banner位管理api请求url配置 */
  // 获取banner列表
  GET_BANNER_LIST: option.ad + '/ad/saas/list',
  // banner操作接口
  BANNER_OPERATION: option.ad + '/ad',
  // 行业分类接口
  GETINDUSTRYLIST: option.enterprise + '/enterprise/industry/tree',
  ADDINDUSTRYLIST: option.enterprise + '/enterprise/industry/add',
  UPDATEINDUSTRYLIST: option.enterprise + '/enterprise/industry/update',
  DELETEINDUSTRYLIST: option.enterprise + '/enterprise/industry/',
  GETALLINDUSTRYLIST: option.enterprise + '/enterprise/industry/list',
  GETALLINDUSTRYINFO: option.enterprise + '/enterprise/industry/',

  // 广告管理
  // 获取列表
  GET_AD_LIST_NEW: option.advertising + '/advertisement/v32x/page/iyouWorkbench',
  // 添加广告
  ADD_ADVERTISING: option.advertising + '/advertisement/v32x/create',
  // 修改广告
  UPDATE_ADVERTISING: option.advertising + '/advertisement/v32x/update',
  // 广告启禁用
  ADVERTISEMENT_DISPLAY_STATUS_UPDATE: option.advertising + '/advertisement/v32x/update-display-status',
  // 删除广告
  ADVERTISEMENT_DELETE: option.advertising + '/advertisement/v32x/delete',
  // 根据id查询广告详情
  GET_ADVERTISING_DETAIL_BY_ID: option.advertising + '/advertisement/v32x/query-detail',
  // GET_ALL_PROJECT_LIST: option.advertising + '/ad/projectList', // 广告位 项目列表
  // 获取所有模块列表
  GET_ALL_MODULE_LIST: option.modular + '/app/module/v2/query/list',
  // 根据项目id和显示位置查询模块列表
  GET_MODULE_LIST_BY_PROJECTID_AND_LOCATION: option.modular + '/app/module/v2/query/list',
  // 获取所有项目
  GET_PROJECT_LIST2: option.project + '/project/app/project',
  // 根据用户权限获取项目
  GET_ALL_PROJECT_BY_USER_AUTH: '/api/dscloud-iyou-user-management/user/project/getProjectList',
  // 获取项目
  GET_SHUNYI_ALL_PROJECTLIST: '/api/project/saas/project/userRole/treeProjectList',
  // 判断是否为顺义项目
  IS_TENANT: option.recruit + '/web/recruit/special/getUserTenantId',
  // 企业列表管理
  GET_ETERPRISELIST: option.enterpriseManagement + '/enterpriseShow/list',
  GET_ETERPRISEPROJECTLIST: option.enterpriseManagement + '/enterpriseShow/searchEnterprise/download',
  DELLSHOWENTERPRISE: option.enterpriseManagement + '/enterpriseShow/',
  ADDSHOWENTERPRISE: option.enterpriseManagement + '/enterpriseShow/add',
  UPDATESHOWENTERPRISE: option.enterpriseManagement + '/enterpriseShow/update',
  DETAILSHOWENTERPRISE: option.enterpriseManagement + '/enterpriseShow/detail/',
  // 静态页管理
  // 获取静态页列表
  GET_STATIC_PAGE_LIST: option.modular + '/web/static/page/list',
  // 添加静态页
  ADD_STATIC_PAGE: option.modular + '/web/static/page/create',
  // 获取静态页详情
  GET_STATIC_PAGE_DETAIL: option.modular + '/web/static/page/detail',
  // 编辑静态页
  EDIT_STATIC_PAGE: option.modular + '/web/static/page/update',
  // 删除静态页
  DELETE_STATIC_PAGE: option.modular + '/web/static/page/deleted',
  /********** 服务模块管理 **********/
  // 获取服务模块列表
  GET_SERVICE_PLATE_LIST: option.policy + '/web/server-binding/list',
  // 添加服务模块
  ADD_SERVICE_PLATE: option.policy + '/web/server-binding/create',
  // 获取服务模块详情
  GET_SERVICE_PLATE_DETAIL: option.policy + '/web/server-binding/detail',
  // 编辑服务模块
  EDIT_SERVICE_PLATE: option.policy + '/web/server-binding/update',
  // 设置服务模块状态
  SET_SERVICE_PLATE_STATUS: option.policy + '/web/server-binding/updateStatus',
  // 删除服务模块
  DELETE_SERVICE_PLATE: option.policy + '/web/server-binding/deleted',
  // 查询可绑定服务或供应商列表
  FIND_ALLOW_BINDING_SERVICE_TYPE_OR_SUPPLIER_LIST: option.policy + '/web/server-binding/find-not-binding-list',
  // 绑定服务类型或供应商
  BINDING_SERVICE_TYPE_OR_SUPPLIER: option.policy + '/web/server-binding/create-binding',
  // 查询已绑定服务类型或供应商列表
  GET_ALREADY_BINDING_SERVICE_TYPE_OR_SUPPLIER: option.policy + '/web/server-binding/find-binding-list',
  // 更新已绑定服务类型排序
  UPDATE_ALREDAY_BINDING_SERVICE_TYPE_SORT: option.policy + '/web/server-binding/update-binding-type-sort',
  // 删除绑定信息
  DELETE_BINDIND_INFO: option.policy + '/web/server-binding/deleted-binding',
  // 需求池服务文案管理
  // 列表
  INTRODUCELIST: option.demand +'/demand/introduce/list',
  // 添加
  ADDINTRODUCE: option.demand +'/demand/introduce/add ',
  // 删除
  DELETEINTRODUCE: option.demand +'/demand/introduce/deleted/',
  // 编辑
  UPDATEINTRODUCE: option.demand +'/demand/introduce/update',
  // 获取详情
  INTRODUCEINFO: option.demand +'/demand/introduce/detail/',

}
