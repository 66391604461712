/*
 * @Author: lizhijie429
 * @Date: 2021-08-20 09:02:14
 * @LastEditors: lizhijie429
 * @LastEditTime: 2021-08-20 09:11:28
 * @Description:
 */
export const CURRENT_ORIGIN = location.origin
export const NODE_ENV = process.env.NODE_ENV || 'development'
export const NOT_PROD = NODE_ENV !== 'production'
export const BASE_API = CURRENT_ORIGIN
// export const BASE_API = NOT_PROD ? '/api' : CURRENT_ORIGIN
export const IMG_API = NOT_PROD ? '/api/aliyun' : (CURRENT_ORIGIN + '/api/aliyun')
export const PRE_FILE_PATH = 'https://sqbj-public.oss-cn-beijing.aliyuncs.com'//生产环境上传的文件域名
export default {
    CURRENT_ORIGIN,
    BASE_API,
    IMG_API,
    PRE_FILE_PATH,
}


//   export const CURRENT_ORIGIN = location.origin;
//   export const isDevelopment = process.env.NODE_ENV === "development";
//   export const IMG_API = isDevelopment ? "/img" : CURRENT_ORIGIN + "/api/aliyun"; // 后边要加 + /oss/files/

  // 成功回调code码
  export const SUCCESS_CODE = "200";

  