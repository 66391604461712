import * as api from '@/api/demandPool';
import { SUCCESS_CODE } from '@/config';
const demandPool = {
  namespaced: true,
  state: () => ({
    roleTypeList: [], // 角色类型列表
    departmentList: [], // 部门列表
  }),
  mutations: {
    UPDATE_ROLE_TYPE_LIST_STATE(state, val) {
      state.roleTypeList = val || [];
    },
    UPDATE_DEPARTMENT_LIST_STATE(state, val) {
      state.departmentList = val || [];
    },
  },
  actions: {
    async getRoleTypeList({ commit, state }) {
      try {
        if (!state.roleTypeList.length) {
          const result = await api.getRoleTypeListForDemandPool();
          if (result.code === SUCCESS_CODE) {
            // let tempRoleTypeList = [{ roleTypeId: '', roleTypeName: '全部' }];
            commit('UPDATE_ROLE_TYPE_LIST_STATE', result.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {},
};
export default demandPool;
