import axios from "axios";
import store from "@/store";

// import config from "@/config";

const axiosConfig = {
  // baseURL: config.API_URL,
  // baseURL: "/api",
  timeout: 25000,
};

const http = axios.create(axiosConfig);
// const token = JSON.parse(localStorage.getItem("user"))
// http.defaults.headers.common["Authorization"] = token.accessToken
http.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

// Add a request interceptor
http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    store.commit("load/UPDATE_LOADING_STATE", true);
    const userInfoStr = localStorage.getItem("user");
    let userInfo = null;
    if (userInfoStr) userInfo = JSON.parse(userInfoStr);
    if (userInfoStr && userInfo && userInfo.accessToken) {
      config.headers.Authorization = userInfo.accessToken
    }
    return config;
  },
  function (error) {
    // Do something with request error
    store.commit("load/UPDATE_LOADING_STATE", true);
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    store.commit("load/UPDATE_LOADING_STATE", false);
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    store.commit("load/UPDATE_LOADING_STATE", false);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

function handleParams(url, rawData, rawMethod, useFunc, isAuth) {
  const method = rawMethod.toUpperCase();
  let data = {};
  if(isAuth) {
    rawData['roleId'] = store.state.common.currentMenuRoleId
  }
  if (useFunc === "params") {
    switch (method) {
      case "GET":
        data = { params: rawData };
        break;
      case "POST":
        data = { params: rawData };
        break;
      case "PUT":
      case "PATCH":
      case "DELETE":
        data = { data: rawData };
        break;
      default:
        data = { data: rawData };
        break;
    }
  } else if (useFunc === 'data') {
    switch (method) {
      case 'GET':
        data = { data: rawData }
        break
      case 'POST':
        data = { data: rawData }
        break
      case 'PUT':
      case 'PATCH':
      case 'DELETE':
        data = { data: rawData }
        break
      default:
        data = { data: rawData }
        break
    }
  }
  return {
    url,
    method,
    data,
  };
}

export const fetchApi = (url, rawData = {}, method = "get", useFunc = "params", isAuth = false) => {
  const options = handleParams(url, rawData, method, useFunc, isAuth);
  return new Promise((resolve, reject) => {
    http({
      withCredentials: true,
      url: options.url,
      method: options.method,
      ...options.data,
    }).then((resp) => {
      const res = resp.data;
      resolve(res);
    }).catch(res => {
      reject(res);
    }),
      (error) => {
        console.log('++++++++', error);
        reject(error);
      };
  });
};

export default http;
