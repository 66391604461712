import "./public-path";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import common from "qiankun-vue2-common";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "element-ui/lib/theme-chalk/index.css";
import './components/Element'
import validator from "validator";
import vcolorpicker from 'vcolorpicker'
import dscloudUI from 'dscloud-ui' 
import 'dscloud-ui/lib/dscloud.css'
import { SUCCESS_CODE } from "@/config"
import buttonPermissions from "@/utils/buttonPermissions"
import VueCropper from 'vue-cropper'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueCropper)
Vue.use(dscloudUI)
Vue.prototype.$validator = validator;
// 全局loading属性
Vue.prototype.$isLoading = store.getters.isLoading;
// 全局成功回调code
Vue.prototype.$SUCCESS_CODE = SUCCESS_CODE;

// 校验按钮权限
Object.keys(buttonPermissions).forEach(key => {
  Vue.prototype[`$${key}`] = buttonPermissions[key];
})

Vue.use(Antd);
Vue.use(vcolorpicker)
Vue.config.productionTip = false;
let instance = null;
function render(props = {}) {
  const { container } = props;
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector("#app") : "#app");
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap(props) {
  console.log("[vue] vue app bootstraped", props);
}
export async function mount(props) {
  console.log("[vue] props from main framework", props);
  common.initGlobalState(store, props);
  render(props);
}
export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = "";
  instance = null;
}
