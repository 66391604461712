import Vue from "vue";
import Vuex from "vuex";
import load from "./modules/loading"
import operationCachedRoutes from './modules/operationCachedRoutes';
import demandPool from "./modules/demandPool";
import serviceManual from "./modules/serviceManual";
import common from './modules/common'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    load,
    operationCachedRoutes,
    demandPool,
    serviceManual,
    common
  },
});
