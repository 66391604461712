/**
 * @file 内容管理按钮权限
 */

export default {
  // 弹框管理
  POPUP_ADMIN: {
    // 添加
    ADD: "content_popupAdmin_add",
    // 查看
    LOOK: "content_popupAdmin_look",
    // 编辑
    EDIT: "content_popupAdmin_edit",
    // 启/禁用
    ENABLE: "content_popupAdmin_enable",
    // 删除
    DELETE: "content_popupAdmin_delete"
  }
}