/**
 * 推广位管理按钮权限配置
 */

export default {
  bannerManagement: {
    add: 'popularizeManagement_bannerManagement_add', // 添加推广位
    look: 'popularizeManagement_bannerManagement_look', // 查看
    edit: 'popularizeManagement_bannerManagement_edit', // 编辑
    enable: 'popularizeManagement_bannerManagement_enable', // 启禁用
    delete: 'popularizeManagement_bannerManagement_delete', // 删除
  }
}