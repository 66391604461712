<template>
  <a-spin :spinning="isLoading" size="large">
    <a-config-provider :locale="locale">
      <div id="app">
        <keep-alive :include="aliveRoutes">
          <router-view />
        </keep-alive>
      </div>
    </a-config-provider>
  </a-spin>
</template>
<script>
//默认国家化 设置中文
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { mapState, mapGetters } from "vuex";
import * as api from "@/api/common";
import { getProjects } from "./api/common";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
    ...mapGetters("operationCachedRoutes", ["aliveRoutes"]),
  },
  watch: {
    $route: {
      async handler(val) {
        console.log(val, '这是router')
        this.$store.commit('common/UPDATE_CURRENT_MENU_ROLE_ID', val.meta.roleId);
        // 查询当前菜单下拥有的权限
        if (val && val.meta && val.meta.roleId) {
          await this.getProjectList(val.meta.roleId)
          await this.getCurrentUserProjectAuth(val.meta.roleId)
        }
      },
      immediate: true
    }
  },
  methods: {
    async getCurrentUserProjectAuth(roleId) {
      const res = await getProjects(roleId);
      const { code, data } = res
      if (code === '200') {
        if (data && data.length) {
          this.$store.dispatch('common/saveCurrentUserProjects', data)
        } else {
          this.$store.dispatch('common/saveCurrentUserProjects', [])
        }
      }
    },
    // 获取当前用户可使用项目
    async getProjectList(roleId) {
      try {
        const res = await api.findCurrentUserAuthorityProjectList(roleId);
        if (res.code === this.$SUCCESS_CODE) {
          this.$store.dispatch('common/updateProjectList', res.data);
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
::v-deep .ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
  height: 100vh;
}
</style>
