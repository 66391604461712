const serviceManual = {
  namespaced: true,
  state: () => ({
    // 当前项目
    currentProject: '',
    // 当前手册id
    currentHandbookId: ''
  }),
  mutations: {
    SAVE_PROJECT(state, val) {
      state.currentProject = val || ''
    },
    SAVE_CURRENT_HANDBOOK_ID(state, val) {
      state.currentHandbookId = val || ''
    }
  },
  actions: {},
  getters: {},
};
export default serviceManual;
