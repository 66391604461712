
const operationCachedRoutes = {
  namespaced: true,
  state: {
    aliveRoutes: []
  },
  getters: {
    aliveRoutes: state => state.aliveRoutes
  },
  mutations: {
    UPDATE_ALIVE_ROUTES: (state, data) => {
      state.aliveRoutes = data
    }
  },
  actions: {
    updateAliveRoutes ({ commit }, payload) {
      commit('UPDATE_ALIVE_ROUTES', payload)
    }

  }
}
export default operationCachedRoutes
