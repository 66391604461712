
const common = {
  namespaced: true,
  state: {
    projectList: [],
    currentMenuRoleId: '',
    projects: []
  },
  getters: {
    projectList: state => state.projectList,
    currentMenuRoleId: state => state.currentMenuRoleId
  },
  mutations: {
    UPDATE_PROJECT_LIST: (state, data) => {
      state.projectList = data
    },
    UPDATE_CURRENT_MENU_ROLE_ID: (state, data) => {
      state.currentMenuRoleId = data
    },
    SAVE_PROJECTS: (state, data) => {
      state.projects = data
    }
  },
  actions: {
    updateProjectList ({ commit }, payload) {
      commit('UPDATE_PROJECT_LIST', payload)
    },
    saveCurrentUserProjects ({ commit }, payload) {
      commit('SAVE_PROJECTS', payload)
    },
    updateCurrentMenuRoleId({ commit }, payload) {
      commit('UPDATE_CURRENT_MENU_ROLE_ID', payload)
    }

  }
}
export default common
