/**
 * 积分商城管理按钮权限配置
 */

export default {
  // 积分商品管理
  integralMerchandiseManagement: {
    // 导出
    export: "integralMallManagement_integralMerchandiseManagement_export",
    // 添加
    add: "integralMallManagement_integralMerchandiseManagement_add",
    // 编辑
    edit: "integralMallManagement_integralMerchandiseManagement_edit",
    // 上下架
    upDownShelf: "integralMallManagement_integralMerchandiseManagement_upDownShelf",
    // 冻结/解冻
    freeze: "integralMallManagement_integralMerchandiseManagement_freeze",
  },
  // 楼层管理
  floorManagement: {
    // 添加
    add: "integralMallManagement_floorManagement_add",
    // 编辑
    edit: "integralMallManagement_floorManagement_edit",
    // 启禁用
    enable: "integralMallManagement_floorManagement_enable",
    // 删除
    delete: "integralMallManagement_floorManagement_delete",
  },
  // 楼层商品管理
  floorCommodityManagement: {
    // 编辑
    edit: "integralMallManagement_floorCommodityManagement_edit",
    // 启禁用
    enable: "integralMallManagement_floorCommodityManagement_enable",
    // 删除
    delete: "integralMallManagement_floorCommodityManagement_delete",
  },
  // 订单管理
  orderManagement: {
    // 导出
    export: "integralMallManagement_orderManagement_export",
    // 查看
    look: "integralMallManagement_orderManagement_look",
    // 关闭
    close: "integralMallManagement_orderManagement_close"
  }
}