import { fetchApi } from '@/utils/axios';
import URL from '@/api/urlConfig';

/**
 * 查询当前用户可使用项目
 * @returns 项目列表
 */
export const findCurrentUserAuthorityProjectList = (roleId) =>
  fetchApi(URL.FIND_CURRENT_USER_AUTHORITY_PROJECT_LIST + `?roleId=${roleId}`, {});

/**
 * 获取企业类型
 */
export const getEnterpriseTypes = () => {
    const params = {
        commonsKey: 'enterprise_type'
    }
    return fetchApi('/api/dscloud-enterprise-center/enterprise/list/commons-dictionary', params, 'get', 'params')
}

/**
 * 改版后的项目列表，有子项目
 */
export const getProjects = (roleId) => {
    const params = {
        roleId
    }
    return fetchApi('/api/project/saas/project/userRole/treeProjectList', params, 'post', 'data')
}

/**
 * 查询当前用户可使用项目新(顺义项目)
 * @param {*} check_type 是否是多选
 * @returns 
 */
export const findCurrentUserAuthorityProjectListNew = (check_type) => fetchApi(URL.FIND_CURRENT_USER_AUTHORITY_PROJECT_LIST_NEW, {check_type}, 'post', 'data', true);
