/**
 * 静态页管理按钮权限配置
 */

export default {
  add: 'staticPageManagement_add', // 添加
  look: 'staticPageManagement_look', // 查看
  edit: 'staticPageManagement_edit', // 编辑
  // enable: 'staticPageManagement_enable', // 启禁用
  delete: 'staticPageManagement_delete', // 删除
};
