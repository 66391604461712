import store from "@/store"
import { checkDataType } from "../checkDataType"

import agreement from "./buttonPermissionsConfig/agreement"
import runUser from "./buttonPermissionsConfig/runUser";
import socialManage from "./buttonPermissionsConfig/socialManage";
import integralMallManagement from "./buttonPermissionsConfig/integralMallManagement";
import CONTENT_MANAGE from "./buttonPermissionsConfig/contentManage";
import socialRecruiting from "./buttonPermissionsConfig/socialRecruiting";
import ranking from "./buttonPermissionsConfig/ranking";
import popularizeManagement from "./buttonPermissionsConfig/popularizeManagement";
import staticPageManagement from "./buttonPermissionsConfig/staticPageManagement";
import entrepreneurClubManagement from "./buttonPermissionsConfig/entrepreneurClubManagement";

/**
 * 校验按钮权限
 * @param {*} element 按钮组件标识
 * @returns Boolean true(包含)/false(不包含)
 */
 export const checkButtonPermissions = element => {
  // 按钮权限集合
  const buttonPermissions = store.state.global.buttonPermissions;
  return buttonPermissions.includes(element);
};

/**
 * 校验是否显示列表页操作栏
 * @param {*} elements 操作栏按钮集合
 * @returns Boolean true(显示)/false(不显示)
 */
export const checkTableActionIsShow = elements => {
  // 按钮权限集合
  const buttonPermissions = store.state.global.buttonPermissions;
  let show = false;
  for (let index = 0; index < elements.length; index++) {
    if (checkDataType(elements[index]) === "Object") {
      if (buttonPermissions.includes(elements[index].permissions)) {
        show = true;
        break;
      }
    }else {
      if (buttonPermissions.includes(elements[index])) {
        show = true;
        break;
      }
    }
  }
  return show;
};

/**
 * 返回列表操作配置
 * @param {*} configurations 自定义配置参数
 * @returns 操作配置数据
 */
export const backTableAction = configurations => {
  const configKeyList = configurations && Object.keys(configurations);
  let tableAction = {
    title: "操作",
    key: "action",
    className: "action-ql-list",
    scopedSlots: { customRender: "action" },
  };
  if (configKeyList?.length) {
    tableAction = { ...tableAction, ...configurations };
  }
  return tableAction;
};

// 按钮权限配置
export const buttonPermissionsConfig = {
  // 协议管理
  agreement,
  // 用户管理
  runUser,
  // 社交管理
  socialManage,
  // 积分商品管理
  integralMallManagement,
  // 内容管理
  CONTENT_MANAGE,
  // 企业招聘管理
  socialRecruiting,
  // 榜单管理
  ranking,
  // 推广位管理
  popularizeManagement,
  // 静态页管理
  staticPageManagement,
  // 服务咨询管理
  entrepreneurClubManagement
};

export default {
  checkButtonPermissions,
  checkTableActionIsShow,
  backTableAction,
  buttonPermissionsConfig,
};
