/**
 * 服务咨询管理
 */
export default {
  serverManagement: {
    servicePlateManagement: {
      add: 'entrepreneurClubManagement_serverManagement_servicePlateManagement_add',
      edit: 'entrepreneurClubManagement_serverManagement_servicePlateManagement_edit',
      enable: 'entrepreneurClubManagement_serverManagement_servicePlateManagement_enable',
      delete: 'entrepreneurClubManagement_serverManagement_servicePlateManagement_delete',
      binding: 'entrepreneurClubManagement_serverManagement_servicePlateManagement_binding'
    },
    bindingAggregationPage: {
      addServiceType: 'entrepreneurClubManagement_serverManagement_bindingAggregationPage_addServiceType', // 添加服务类型
      addSupplier: 'entrepreneurClubManagement_serverManagement_bindingAggregationPage_addSupplier', // 添加供应商
      deleteServiceType: 'entrepreneurClubManagement_serverManagement_bindingAggregationPage_deleteServiceType', // 删除服务类型
      deleteSupplier: 'entrepreneurClubManagement_serverManagement_bindingAggregationPage_deleteSupplier', // 删除服务类型
      serviceTypeSort: 'entrepreneurClubManagement_serverManagement_bindingAggregationPage_serviceTypeSort'
    }
  }
}