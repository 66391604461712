export default {
  // 榜单数据管理
  rankingData: {
    // 编辑
    edit: 'ranking_rankingData_edit',
    // 导出
    export: 'ranking_rankingData_export',
  },
  // 推荐内容管理
  recommend: {
    // 编辑
    edit: 'ranking_recommend_edit',
    // 删除
    remove: 'ranking_recommend_remove',
    // 添加
    add: 'ranking_recommend_add',
    // 导出
    export: 'ranking_recommend_export',
  },
  //企服活动
  enterpriseServiceActivitiesManagement:{
    enterpriseServiceActiveMaintenance:'enterpriseServiceActivitiesManagement_add'
  }
};
