/**     
 * 校验数据类型文件
 */

export const checkDataType = data => {
  const dataType = Object.prototype.toString.call(data);
  return dataType.slice(8, -1);
}

export default {
  checkDataType
};